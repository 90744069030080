import Vue from "vue";
import App from "./App.vue";
import "@/core/util/flat";
import axios from "@/core/services/axios";
import VueAxios from "vue-axios";
import router from "./router";
import store from "@/core/services/store";
// import { VERIFY_AUTH } from "@/core/services/store/auth.module";
// import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import "@/core/util/directives.js";
// element
import ElementUI from "element-ui";
import Antd from "ant-design-vue";
Vue.use(Antd);
import "ant-design-vue/dist/antd.css";
// import "element-ui/lib/theme-chalk/index.css";
// Vue.prototype.$antdMessage = Vue.prototype.$message;
import "@/element/theme/index.css";
import "vue-easytable/libs/theme-default/index.css";
// 引入组件库
import VueEasytable from "vue-easytable";

Vue.use(VueEasytable);
import filters from "@/core/util/filters.js";
import blackMessage from "./views/newMark/components/blackMessage";
import noData from "@/components/noData";
Vue.use(blackMessage);

// 兄弟组件通知
import plugin from "./components/js/bus";
Vue.use(plugin);

import "@/sass/global.scss";

Vue.component("NoData", noData);
// 加载过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
import unionForShowByField from "@/core/util/unionAssert";
import chartLoading from "@/core/util/loading";
import resize from "@/core/util/resize";
Vue.use(chartLoading);
Vue.use(unionForShowByField);
Vue.use(resize);

// Axios
window.axios = axios;
Vue.use(VueAxios, axios);
// ElementUI.Dialog.props.lockScroll.default = false;
// ElementUI.confirm.props.lockScroll.default = false;

Vue.use(ElementUI);
Vue.config.productionTip = false;

var app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
export { app };
